<template>
  <v-card min-height="60vh">
    <v-card-title :class="this.mobileCompatible ? 'secondary pa-0 flex-nowrap' : 'secondary'">
      <DialogWithTabsTitle
      @menu-clicked="navDrawer = !navDrawer"
      :mobileCompatible="mobileCompatible"
      :titleText="accountName"
      hideRefresh
      :tabs="tabs"
      @tab-clicked="onTabClicked"
      :enlargeTabs="enlargeNavigationTabs"
      v-if="!loading"
      >
        <template #append-title>
          <Icon
          v-if="!loading"
          iconColor="tertiary"
          dataTestId="account-detail-isexternal"
          :icon="account.isExternal ? 'mdi-domain' : 'mdi-home'"
          :small="false"
          :tooltipText="account.isExternal ? $t('external') : $t('internal')"
          />
        </template>
        <template #prepend-base-actions>
          <v-menu bottom left>
            <template #activator="{ on }">
              <v-icon v-on="on" color="white">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item v-for="(ha, index) in headerActions" :key="`${ha.text}-${index}`" @click="ha.action(account)" :disabled="ha.disabled">
                <v-avatar left>
                  <v-icon color="black" :disabled="ha.disabled">{{ha.icon}}</v-icon>
                </v-avatar>
                <v-list-item-title class="subtitle-1">{{ $t(ha.text) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </DialogWithTabsTitle>

    </v-card-title>
    <v-card-text :class="mobileCompatible ? 'pa-0' : ''">
      <v-container fluid>
        <v-row v-if="loading" justify="center" align="center">
          <v-progress-circular indeterminate color="secondary"/>
        </v-row>
        <v-row v-if="!loading">
          <v-col :class="mobileCompatible ? 'pa-0' : ''">
            <v-tabs v-model="currentTab" v-if="!mobileCompatible">
              <v-tab v-for="(item, index) in tabs" :key="index" :value="index">{{ item }}</v-tab>
            </v-tabs>
            <v-window v-model="currentTab" v-if="!loading" touchless>

              <v-window-item> <!-- Properties -->
                <v-row class="ma-0">
                  <v-col cols="12">
                    <span class="subtitle-1">
                      {{$t('generalInformation')}}
                    </span>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field data-testid="account-details-code" :label="$t('code')" outlined color="secondary" readonly :value="code"/>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field data-testid="account-details-export-code" :label="$t('exportCode')" outlined color="secondary" readonly :value="exportCode"/>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field data-testid="account-details-export-code" :label="$t('settlementDeliveryMode')" outlined color="secondary" readonly :value="SettlementDeliveryMode.fromInt(account.settlementDeliveryMode)"/>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field :label="$t('accountTimezone')" outlined color="secondary" readonly :value="timezoneOffset"/>
                  </v-col>
                  <v-col cols="12" lg="4" xl="4" class="mt-n6">
                    <span class="subtitle-1">
                      {{$t('tags')}}
                    </span>
                    <br>
                    <v-chip :data-testid="`account-details-tag-${index}`" color="secondary" v-for="(tag, index) in account.accountTags" :key="`${tag.tagId}`" class="mr-1 mb-1">
                      {{tag.value}}
                    </v-chip>
                    <span v-if="! (account?.accountTags?.length > 0)" data-testid="account-details-no-tag">{{$t('noneSpecified')}}</span>
                  </v-col>
                  <v-col cols="12">
                    <span class="subtitle-1">
                      {{ $t('receiverLocation') }}
                    </span>
                  </v-col>
                  <v-col cols="12" v-if="account.receiverSpot">
                    <v-row>
                      <v-col cols="12" sm="6" lg="4" xl="2">
                        <v-text-field
                        :label="$t('latitude')"
                        v-model="account.receiverSpot.latitude"
                        readonly
                        outlined
                        color="black"/>
                      </v-col>
                      <v-col cols="12" sm="6" lg="4" xl="2">
                        <v-text-field
                        :label="$t('longitude')"
                        v-model="account.receiverSpot.longitude"
                        readonly
                        outlined
                        color="black"/>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-else>
                    <span class="pt-4">{{ $t('noneSpecified') }}</span>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item> <!-- Contact Info -->
                <v-row class="ma-0">
                  <v-col cols="12">
                    <span class="subtitle-1">
                      {{$t('contacts')}}
                    </span>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" v-for="(contact, index) in account.contacts" :key="`contact-${index}`">
                    <ContactCard
                    :showCrud="false"
                    :contact="contact"/>
                  </v-col>
                  <v-col v-if="account?.contacts?.length < 1">
                    <span>{{ $t('noneSpecified') }}</span>
                  </v-col>
                  <v-col cols="12">
                    <span class="subtitle-1">
                      {{$t('addresses')}}
                    </span>
                  </v-col>
                  <v-col cols="12" xs="12" md="12" lg="4" v-for="(address, index) in account.addresses" :key="`address-${index}`">
                    <AddressCard
                    :address="address"
                    :showCrud="false"
                    />
                  </v-col>
                  <v-col cols="12" v-if="account.addresses.length === 0">
                    <span>{{$t('noneSpecified')}}</span>
                  </v-col>
                </v-row>
              </v-window-item>

              <v-window-item> <!-- Advances -->
                <v-col v-if="mobileCompatible" cols="12">
                  <span class="subtitle-1">
                    {{$t('advances')}}
                  </span>
                </v-col>
                <v-col v-if="!loading">
                  <AccountAdvances
                    :account="account"
                    accountDetail
                  />
                </v-col>
              </v-window-item>

              <v-window-item> <!-- Insurance -->
                <v-col v-if="hasInsurancePolicy">
                  <v-row v-if="account.insurancePolicies?.length > 0">
                    <v-col v-if="mobileCompatible" cols="12">
                      <span class="subtitle-1">
                        {{ $t('insurancePolicies') }}
                      </span>
                    </v-col>
                    <v-col v-for="(insurancePolicy, index) in orderedInsurancePolicies" :key="`policy-${index}`" cols="12" md="6" lg="4">
                      <InsuranceCard
                      :showCrud="false"
                      :insurancePolicy="insurancePolicy"
                      :file="filesByInsurancePolicy(insurancePolicy)"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="4">
                      <InsuranceCard
                      :showCrud="false"
                      :insurancePolicy="{name: $t('defaultInsurance'), expirationDate: account.insuranceExpiryDate}"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else>
                  <v-row>
                    <v-col v-if="mobileCompatible" cols="12">
                      <span class="subtitle-1">
                        {{ $t('insurancePolicies') }}
                      </span>
                    </v-col>
                    <v-col>
                      <span>{{ $t('noneSpecified') }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-window-item>

              <v-window-item> <!-- Certifications -->
                <v-col cols="12">
                  <v-row>
                    <v-col v-if="mobileCompatible" cols="12">
                      <span class="subtitle-1">{{$t('certifications')}}</span>
                    </v-col>
                    <v-col>
                      <AccountCertifications :accountId="accountId" :files="filesForCertifications()"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-window-item>

              <v-window-item>
                <v-col cols="12">
                  <v-row>
                    <v-col v-if="mobileCompatible" cols="12">
                      <span class="subtitle-1">{{ $t('payments') }}</span>
                    </v-col>
                    <v-col>
                      <AccountPayments :accountId="accountId"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>

      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { generateAccountIdSheetPDF, getAccountQrCodeData } from '@/utils/pdf-formatters/AccountIdentification'
import { getLegnaQrCodeUrl } from '@/utils/LegnaQrCode'
import { UserClaims, SettlementDeliveryMode } from '../../../utils/Enumerations'
import { userAssignedClaim } from '@/utils/ClaimUtility'
import moment from 'moment'
import { AccountFileCategory } from '@/utils/Enumerations'
import { accountFilesClient } from '../../../utils/AccountFiles'
import timezones from '@/components/settings-components/company-config/timezones.json'

export default {
  name: 'AccountDetail',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ContactCard: () => import('@/components/account/contacts/ContactCard.vue'),
    AddressCard: () => import('@/components/account/address/AddressCard.vue'),
    AccountAdvances: () => import('@/components/account/advances/AccountAdvances.vue'),
    DialogWithTabsTitle: () => import('@/components/core/DialogWithTabsTitle.vue'),
    InsuranceCard: () => import('@/components/account/Insurance/InsuranceCard.vue'),
    AccountCertifications: () => import('@/components/account/certifications/AccountCertifications.vue'),
    AccountPayments: () => import('@/components/account/account-payables/AccountPayments.vue')
  },

  props: {
    accountId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    account: undefined,
    loading: false,
    currentTab: 0,
    mobileCompatible: false,
    navDrawer: false,
    enlargeNavigationTabs: false,
    files: undefined,
    SettlementDeliveryMode
  }),

  computed: {
    ...mapGetters('user', ['companyInfo']),
    accountName () {
      if (!this.account) return ''
      return this.account.name
    },
    code () {
      if (!this.account) return ''
      return this.account.code || this.$t('notSpecified')
    },
    exportCode () {
      if (!this.account) return ''
      return this.account.exportCode || this.$t('notSpecified')
    },
    timezoneOffset () {
      if (!this.account) return ''
      return timezones.find(tz => Number(tz.offset) === this.account.timeZoneOffset).name
    },
    insuranceExpiryDate () {
      if (!this.account) return ''
      return this.account.insuranceExpiryDate ? utcToLocalDate(this.account.insuranceExpiryDate) : this.$t('notSpecified')
    },
    orderedInsurancePolicies () {
      return [...this.account.insurancePolicies].sort((a, b) => {
        const aDate = moment.utc(a.expirationDate)
        const bDate = moment.utc(b.expirationDate)
        return (moment.duration(aDate.diff(bDate)).asDays())
      })
    },
    headerActions () {
      return (this.account) ? [
        {
          text: 'editAccount',
          icon: 'mdi-pencil',
          action: a => this.$emit('edit-account', a),
          disabled: !userAssignedClaim(UserClaims.AccountManager)
        },
        {
          text: 'showQrCode',
          icon: 'mdi-qrcode',
          action: this.showQrCode,
          disabled: !this.account.isExternal
        },
        {
          text: 'downloadIdSheet',
          icon: 'mdi-download',
          action: this.generatePdf,
          disabled: !this.account.isExternal
        }
      ] : []
    },
    hasInsurancePolicy () {
      return this.account.insurancePolicies?.length > 0 || this.account.insuranceExpiryDate
    },
    tabs () {
      return [
        this.$t('properties'),
        this.$t('contactInfo'),
        this.$t('advances'),
        this.$t('insurance'),
        this.$t('certifications'),
        this.$t('payments')
      ]
    },

    accountFileCategory () {
      return AccountFileCategory
    }
  },

  async created () {
    this.loading = true
    this.getAccount(this.accountId)
    await this.getFiles()
  },

  mounted () {
    this.onMobileInverted()
    window.addEventListener('resize', this.onMobileInverted)
  },

  beforeDestroy () {
    document.removeEventListener('resize', this.onMobileInverted)
  },

  methods: {
    ...mapActions('snackbar', ['setSnackError']),
    ...mapActions('account', ['fetchAccountById']),
    async getAccount (accountId) {
      this.loading = true
      try {
        this.account = await this.fetchAccountById(accountId)
      } finally {
        this.loading = false
      }
    },

    checkAccountCode (account) {
      if (account.code) return true

      this.setSnackError(this.$t('missingQrAccountCode'))
      return false
    },

    showQrCode (account) {
      if (this.checkAccountCode(account)) this.$emit('show-qrcode', account)
    },

    async generatePdf (account) {
      if (this.checkAccountCode(account)) {
        const text = getAccountQrCodeData(account, this.companyInfo)
        const qrCode = await getLegnaQrCodeUrl(250, text)
        generateAccountIdSheetPDF(account, qrCode)
      }
    },

    onMobileInverted () {
      if (window.innerWidth < 991) {
        this.mobileCompatible = true
        this.enlargeNavigationTabs = window.innerHeight > 600
      } else {
        this.mobileCompatible = false
      }
    },

    onTabClicked (t) {
      this.currentTab = t
    },

    async getFiles () {
      this.files = await accountFilesClient.getFilesForAccount(this.accountId)
    },

    filesByInsurancePolicy (insurancePolicy) {
      return this.files?.find(f => Number(f.policyId) === insurancePolicy.accountInsurancePolicyId && f.fileCategory === this.accountFileCategory.Insurance.value)
    },

    filesForCertifications () {
      return this.files?.filter(f => f.fileCategory === this.accountFileCategory.Certification.value)
    }
  }
}
</script>
