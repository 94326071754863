<template>
  <v-row dense>
    <v-col cols="12" xs="12" sm="12" md="12" lg="3">
      <v-select
        v-model="selectedBusinessEntity"
        :label="$t('templateEntity')"
        :items="businessEntities"
        :disabled="businessEntities.length < 2"
        :no-data-text="$t('noBusinessEntitiesWithActivityTemplates')"
        color="black"
        item-color="secondary"
        item-text="businessEntityName"
        return-object
        data-testid="template-entity"
      />
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="3">
      <v-select
        v-model="accountingCategory"
        :label="$t('accountingCategory')"
        :items="filteredCategories"
        color="black"
        item-color="secondary"
        item-text="name"
        data-testid="template-accounting-category"
      />
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="6">
      <v-autocomplete
      v-model="activityTemplate"
      :data-testid="dataTestid"
      :label="$t('activityTemplates')"
      :filter="templateFilter"
      :items="filteredTemplates"
      ref="templateField"
      color="black"
      return-object
      item-value="activityTemplateId"
      >
        <template #item="{item}">
          <v-container>
            <v-row dense>
              <v-col cols="auto" v-if="accountingCategory === $t('accountsPayable')">
                <Icon
                dataTestId="template-cost-type"
                icon-color="secondary"
                :icon="costIcons[item.costType]"
                :tooltipText="costTypeFromInt(item.costType)"
                />
              </v-col>
              <v-col>
                {{item.name}}
                <Icon
                v-if="item.specialization === TemplateSpecialization.Depletion.value"
                icon="mdi-wallet-bifold"
                tooltipColor="black"
                :tooltipText="$t('depletionActivity')"
                iconColor="black"/>
                <Icon
                v-if="item.specialization === TemplateSpecialization.Transfer.value"
                icon="mdi-transfer"
                iconColor="grey'"
                tooltipColor="grey"
                :tooltipText="$t('transferActivity')"
                />
              </v-col>
              <v-col>
                <span>{{item.glCode}} {{item.glOffset ? `(${item.glOffset})`  : ''}}</span>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template #selection="{item}">
          <Icon
          dataTestId="template-selection-cost-type"
          icon-color="secondary"
          :icon="costIcons[item.costType]"
          :tooltipText="costTypeFromInt(item.costType)"
          />
          {{item.name}}
          <Icon
          v-if="item.specialization === TemplateSpecialization.Depletion.value"
          icon="mdi-wallet-bifold"
          tooltipColor="black"
          :tooltipText="$t('depletionActivity')"
          iconColor="black"/>
          <Icon
          v-if="item.specialization === TemplateSpecialization.Transfer.value"
          icon="mdi-transfer"
          iconColor="grey'"
          tooltipColor="grey"
          :tooltipText="$t('transferActivity')"
          />
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { TemplateAccountingCategory, ActivityStatus, CostType, PayOn, TemplateSpecialization } from '@/utils/Enumerations.js'
import Cookies from 'js-cookie'
import { CookieKeys } from '@/utils/constants.js'
import { mapActions } from 'vuex'
export default {
  name: 'TemplateAutocomplete',

  props: {
    propAccountingCategories: Array,
    dataTextIds: Array,
    templateId: Number,
    focus: Boolean,
    dataTestid: {
      type: String,
      default: ''
    },
    includeModifiers: {
      type: Boolean,
      default: false
    },
    includeTransferActivities: {
      type: Boolean,
      default: false
    },
    includeReceivables: {
      type: Boolean,
      default: true
    },
    includeDefectActivities: {
      type: Boolean,
      default: true
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    TemplateSpecialization,
    businessEntities: [],
    activityTemplates: [],
    costIcons: ['mdi-alpha-u-circle-outline', 'mdi-alpha-p-circle-outline', 'mdi-alpha-s-circle-outline', 'mdi-alpha-n-circle-outline'],
    accountingCategory: TemplateAccountingCategory.names[0],
    selectedBusinessEntity: undefined,
    activityTemplate: undefined
  }),

  created () {
    this.getInitialData()
  },

  computed: {
    accountingCategories () {
      return (!this.includeReceivables)
        ? TemplateAccountingCategory.names.filter((_, index) => index !== 1)
        : TemplateAccountingCategory.names
    },
    filteredTemplates () {
      if (!this.selectedBusinessEntity || this.filteredCategories.length === 0) {
        return []
      }

      const includeCategory = (template) => template.category === TemplateAccountingCategory.toInt(this.accountingCategory)
      const includeTransfer = (template) => template.specialization !== TemplateSpecialization.Transfer.value || this.includeTransferActivities
      const includeActive = (template) => template.activityStatus === ActivityStatus.Active.value || template.activityTemplateId === this.templateId
      const includeDefect = (template) => (template.baseCost?.payOn !== PayOn.Defect.value && template.baseCost?.payOn !== PayOn.NatureDefect.value && template.baseCost?.payOn !== PayOn.MismanufacturedDefect.value) || this.includeDefectActivities

      return this.selectedBusinessEntity.templates
        .filter(t => includeCategory(t) && includeTransfer(t) && includeActive(t) && includeDefect(t))
    },

    filteredCategories () {
      if (!this.selectedBusinessEntity) {
        return []
      }

      if (!this.propAccountingCategories) {
        return this.selectedBusinessEntity.categories
      }

      return this.selectedBusinessEntity.categories
        .filter((category) =>
          this.propAccountingCategories.includes(TemplateAccountingCategory.toInt(category))
        )
    }
  },

  watch: {
    accountingCategory (val) {
      if (!this.activityTemplate) {
        return
      }

      this.activityTemplate = (val === TemplateAccountingCategory.fromInt(this.activityTemplate.category))
        ? this.activityTemplate
        : undefined
    },

    selectedBusinessEntity (val, oldVal) {
      this.accountingCategory = this.activityTemplate
        ? TemplateAccountingCategory.fromInt(this.activityTemplate.category)
        : val.categories[0]

      if (oldVal) this.activityTemplate = undefined
    },

    focus (val) {
      if (val) { this.$refs.templateField.focus() }
    },

    activityTemplate (val) {
      this.$emit('template-chosen', this.activityTemplate)
    },

    async propAccountingCategories (val) {
      if (!!this.activityTemplate && !val.includes(this.activityTemplate?.category)) {
        this.activityTemplate = undefined
        this.activityTemplates = await this.fetchActivityTemplates()
      }
      if (val.some(ac => TemplateAccountingCategory.fromInt(ac) !== this.accountingCategory)) {
        this.accountingCategory = TemplateAccountingCategory.fromInt(this.propAccountingCategories[0])
      }
    }
  },

  beforeDestroy () {
    if (this.selectedBusinessEntity) {
      Cookies.set(CookieKeys.TEMPLATE_FORM_BUSINESS_ENTITY, this.selectedBusinessEntity.businessEntityId)
    }
  },

  methods: {
    ...mapActions('activity-templates', ['fetchActivityTemplates']),
    ...mapActions('user', ['fetchAllBusinessEntities']),
    costTypeFromInt: (x) => CostType.fromInt(x),
    async getInitialData () {
      const activityTemplates = await this.fetchActivityTemplates()
      const businessEntities = this.getBusinessEntities(activityTemplates)
      this.businessEntities = businessEntities
      this.setInitialItems(businessEntities)
    },

    setInitialItems (businessEntities) {
      if (this.templateId) {
        for (let i = 0; i < businessEntities.length; i++) {
          const entity = businessEntities[i]
          const activityTemplate = entity.templates.find(t => t.activityTemplateId === this.templateId)

          if (activityTemplate) {
            this.selectedBusinessEntity = entity
            this.accountingCategory = TemplateAccountingCategory.fromInt(activityTemplate.category)
            this.activityTemplate = activityTemplate
            break
          }
        }

        return
      }

      const businessEntityFromCookie = businessEntities.find(be => Cookies.get(CookieKeys.TEMPLATE_FORM_BUSINESS_ENTITY) === String(be.businessEntityId))
      this.selectedBusinessEntity = businessEntityFromCookie || businessEntities[0]
      this.accountingCategory = this.selectedBusinessEntity.categories[0]
      if (this.selectedBusinessEntity.templates.filter(template => template.category === TemplateAccountingCategory.toInt(this.accountingCategory) &&
          template.activityStatus === 0).length === 1) {
        this.activityTemplate = this.selectedBusinessEntity.templates[0]
      }
    },

    getBusinessEntities (templates) {
      let businessEntities = {}

      for (const template of templates) {
        if (!this.includeModifiers && template.modifier === 1) { continue }

        if (businessEntities[template.businessEntityId]) {
          businessEntities[template.businessEntityId].templates.push(template)
        } else {
          businessEntities[template.businessEntityId] = {
            businessEntityId: template.businessEntityId,
            businessEntityName: template.businessEntityName,
            businessEntityExportCode: template.businessEntityExportCode,
            templates: [template]
          }
        }
      }

      businessEntities = Object.values(businessEntities).map(to => {
        const entityCategories = to.templates
          .map(tt => tt.category)
          .filter(tt => this.includeReceivables || tt !== 1)

        return {
          ...to,
          categories: [...new Set(entityCategories)].map(c => TemplateAccountingCategory.fromInt(c))
        }
      })
      return businessEntities
    },

    templateFilter (item, queryText) {
      const { glCode, glOffset, name } = item
      return glCode.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
        glOffset.toLowerCase().indexOf(queryText.toLowerCase()) > -1 ||
        name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    }
  }
}
</script>
